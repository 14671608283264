import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import logo_w from "../../icons/logo_w.svg"

const Footer = () => {
    const data = useStaticQuery(graphql`
      query {
        siteData: allDataJson {
            edges {
              node {
                footer {
                    about{
                        title
                        ps{
                            p
                            url
                        }
                    }
                    contact{
                        title
                        ps{
                            p
                            url
                        }
                    }
                    copyright
                }
              }
            }
          }
      }
    `)
    const content = data.siteData.edges.filter((edge)=> {return edge.node.footer !== null})[0].node.footer
    return(
        <div id="footer">
            <div  className="container" >
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div id="logo-wh">
                            <Link className="logo" to="/">
                                <img src={logo_w} alt="logo 翻墙101" /> 
                            </Link>
                        </div>
                        <div id="intro">
                            <p>我们致力于通过最专业科学的评测与大数据分析，提供最为可靠专业的翻墙建议</p>
                            <p>自从2018年以来，我们已经帮助无数的人找到了适合他们的VPN工具</p>
                            <p>中国测速站点：
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E4%B8%8A%E6%B5%B7%E5%B8%82">上海市</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E5%8C%97%E4%BA%AC%E5%B8%82">北京市</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E6%B5%99%E6%B1%9F%E7%9C%81%E6%9D%AD%E5%B7%9E%E5%B8%82">浙江杭州</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E5%B1%B1%E4%B8%9C%E7%9C%81%E9%9D%92%E5%B2%9B%E5%B8%82">山东青岛</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E6%B1%9F%E8%8B%8F%E7%9C%81%E5%8D%97%E4%BA%AC%E5%B8%82">江苏南京</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E5%B9%BF%E4%B8%9C%E7%9C%81%E6%B7%B1%E5%9C%B3%E5%B8%82">广东深圳</a>&nbsp;
                                <a href="https://www.google.com/maps/place/%E4%B8%AD%E5%9B%BD%E5%9B%9B%E5%B7%9D%E7%9C%81%E6%88%90%E9%83%BD%E5%B8%82">四川成都</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 content">
                        <h2>{content.about.title}</h2>
                        <div className="lists">
                            {content.about.ps.map((e)=>(
                                <div className="item">
                                    <p className="p-content">
                                        <Link to={e.url}>{e.p}</Link>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div class="col-6 col-lg-3 content">
                        <h2>{content.contact.title}</h2>
                        <div className="lists">
                            {content.contact.ps.map((e)=>(
                                <div className="item">
                                   <p className="p-content">
                                        <a href={e.url}>{e.p}</a>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div id="rights" class="row">
                    <p>{content.copyright}</p>
                </div>
            </div>
        </div>
    )
}
export default Footer